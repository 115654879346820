import React, { useState } from "react"
import { Trans } from "react-i18next"
import { Table } from 'react-bootstrap'
import { utcStringToLocalString } from "../../../helpers/dateHelper"
import { Link } from "react-router-dom"
import EditRidePopUp from "../ScheduledRides/EditRide/EditRidePopUp";
import '../../Tables/TableStyle.css'
import CannabisIcon from "../../../assets/img/cannabis.svg";
import DeliveryIcon from "../../../assets/img/bag.svg";
import RidesharingIcon from "../../../assets/img/car.svg";
import SweetAlert from "react-bootstrap-sweetalert";
import i18n from "i18next";
import { cancelCommunityCustomerRide, demergeRide } from "../../../helpers/apiHelper";
import BulkIcon from "../../../assets/img/bag.svg"; //TODO update with Truck icon
import { Button, DropdownButton, MenuItem } from "react-bootstrap";
import { convertToFiat } from "../../../helpers/paymentHelper";
import RideServiceNames from "../../../const/RideServiceNames";
import { miaBaseLink } from "../../../variables/secretEnvVariables";
import { SCHEDULED_RIDE_STATUS } from "../../../const/RideStatus"


const RIDE_SERVICE_TYPE_ICONS = {
  0: RidesharingIcon,
  1: DeliveryIcon,
  2: CannabisIcon,
  3: BulkIcon,
  4: DeliveryIcon,
  5: DeliveryIcon,
  6: RidesharingIcon,
  7: RidesharingIcon,
  8: RidesharingIcon,
  9: DeliveryIcon,
  10: RidesharingIcon,
  11: BulkIcon
}

const DriverRouteSubTable = (props) => {
  const [edit, setEdit] = useState(false);
  const [alert, setAlert] = useState(null);
  const [selectedRide, setSelectedRide] = useState("");

  const hideAfterconfirmAlert = () => {
    setAlert(null);
  }

  const getTrackingLinkComponent = (tracking_id) => {
    return (
      <Button
        style={{ padding: "1%", margin: 0, height: 20 }}
        onClick={() => {
          const link = miaBaseLink + "?tracking_id=" + tracking_id;
          window.open(link, "_blank")
        }}
        bsSize="xsmall"
        bsStyle="success"
      >
        <Trans>TRACK_RIDE</Trans>
      </Button>
    );
  };


  const showRideInfoAlert = (row) => {
    setAlert(
      <SweetAlert
        style={{ display: "block", marginTop: -250 }}
        onConfirm={() => hideAfterconfirmAlert()}
        confirmBtnBsStyle="success"
      >
        <div style={{ textAlign: "center" }}>
          <div style={{ width: "60%", display: "inline-block" }}>
            <p style={{ textAlign: "left" }}>
              <b>
                Tracking id
              </b>
              : {row.tracking_id} <br />
              <b>
                <Trans>SCHEDULED_DATE</Trans>
              </b>
              : {row.scheduled_datetime} <br />
              <b>
                <Trans>ONSITE_DATE</Trans>
              </b>
              : {row.onsite_datetime} <br />
              <b>
                <Trans>FROM_ACCOUNT</Trans>
              </b>
              : {row.from_address} <br />
              <b>
                <Trans>TO_ACCOUNT</Trans>
              </b>{" "}
              : {row.to_address} <br />
              {(row.ride_request_id && row.status_id !== 0) &&
                <>
                  <b>
                    <Trans>RIDE_REQUEST</Trans>
                  </b>
                  :
                  <Link onclick={(e) => e.stopPropagation()} to={`/ride-request-summary?reqId=${row.ride_request_id}`} target="_blank" rel="noopener noreferrer">
                    {row.ride_request_id}
                  </Link> <br />
                </>
              }
              <b>
                <Trans>RIDE_SERVICE_NAME</Trans>
              </b>
              : {i18n.t(RideServiceNames[row.ride_service_name])} <br />
              <b>
                <Trans>TIP_AMOUNT</Trans>
              </b>
              : {convertToFiat(row.tip_token_amount, row.scheduled_datetime)} <br />
              {row.note &&
                <div>
                  <b>
                    <Trans>NOTE</Trans>
                  </b>
                  : {row.note} <br />
                </div>}
              {row.total_order_amount >= 0 &&
                <div>
                  <b>
                    <Trans>TOTAL_ORDER_AMOUNT</Trans>
                  </b>
                  : {convertToFiat(row.total_order_amount, row.scheduled_datetime)} <br />
                </div>}
              <br />
              <b>
                <Trans>RIDER</Trans>
              </b>
              : {row.rider_name || row.rider} <br />
              <b>
                <Trans>ORDER_NUMBER</Trans>
              </b>
              : {row.order_number} <br />
              <b>
                <Trans>CUSTOMER_NAME</Trans>
              </b>
              : {row.customer_name} <br />
              <b>
                <Trans>CUSTOMER_PHONE</Trans>
              </b>
              : {row.customer_phone} <br />
              <b>
                <Trans>SCHEDULED_RIDE_HEALTHCHECK</Trans>
              </b>
              : {getProblemCheckComponent(row.problem_flag)} <br />
              <b>
                <Trans>TRACKING_LINK</Trans>
              </b>
              : {getTrackingLinkComponent(row.tracking_id)} <br />
            </p>
          </div>
        </div>
      </SweetAlert>
    );
  };

  const toggleEditPopUp = () => {
    setEdit(!edit);
  };
  const editRideAlert = (scheduledRide) => {
    setSelectedRide(scheduledRide);
    toggleEditPopUp();
  };

  const showSuccessAlert = () => {
    setAlert(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Confirmation"
        onConfirm={() => window.location.reload()}
        onCancel={() => setAlert(null)}
        confirmBtnBsStyle="success"
      >
        <p>
          <Trans>ACTION_SUCCESS</Trans>
        </p>
      </SweetAlert>
    )
  };

  const showErrorAlert = (error) => {
    setAlert(
      <SweetAlert
        danger
        style={{
          display: "block",
          marginTop: "-100px",
        }}
        title="Error"
        onConfirm={() => setAlert(null)}
        confirmBtnBsStyle="info"
      >
        <p>
          An Error has occurred : <br></br>
          {error.message}{" "}
        </p>
      </SweetAlert>
    )
  };

  const loadingHandlerAlert = () => {
    setAlert(<SweetAlert
      style={{ display: "block", marginTop: "-100px" }}
      title={<Trans>SENDING</Trans>}
      closeOnClickOutside={false}
      allowEscape={false}
      showCancel={false}
      showConfirm={false}
    >
      <div class="spinner">
        <div class="bounce1"></div>
        <div class="bounce2"></div>
        <div class="bounce3"></div>
      </div>
      <p>
        <Trans>SENDING_REQUEST</Trans>
      </p>
    </SweetAlert>
    );
  };

  const confirmAlert = (scheduledRide, action) => {
    let title, onConfirm, onCancel, confirmBtnText, cancelBtnText, paragraph;

    switch (action) {
      case "cancel_ride":
        title = <Trans>CANCEL_ACTIVE_RIDE_ALERT_TITLE</Trans>;
        onConfirm = async () => {
          loadingHandlerAlert();
          const res = await cancelCommunityCustomerRide(scheduledRide.ride_request_id);
          if (res.data.success) {
            showSuccessAlert();
          } else {
            showErrorAlert(res.data);
          }
        }
        onCancel = () => setAlert(null);
        confirmBtnText =
          alert === null ? (
            <Trans>CANCEL_ACTIVE_RIDE_ALERT_CONFIRM</Trans>
          ) : (
            <Trans>ALERT_LOADING</Trans>
          );
        cancelBtnText = <Trans>CANCEL</Trans>;
        paragraph = (
          <p>
            <Trans>CANCEL_ACTIVE_CORP_RIDE_ALERT_BODY</Trans>
            <br></br>
            <Trans>WANT_TO_PROCEED</Trans>
          </p>
        );
        break;
        case "demerge_ride":
          title = <Trans>DEMERGE_RIDE_ALERT_TITLE</Trans>;
          onConfirm = async () => {
            loadingHandlerAlert();
            const res = await demergeRide(scheduledRide.ride_request_id);
            if (res.data.success) {
              showSuccessAlert();
            } else {
              showErrorAlert(res.data);
            }
          }
          onCancel = () => setAlert(null);
          confirmBtnText =
            alert === null ? (
              <Trans>DEMERGE_RIDE_ALERT_CONFIRM</Trans>
            ) : (
              <Trans>ALERT_LOADING</Trans>
            );
          cancelBtnText = <Trans>CANCEL</Trans>;
          paragraph = (
            <p>
              <Trans>DEMERGE_CORP_RIDE_ALERT_BODY</Trans>
              <br></br>
              <Trans>WANT_TO_PROCEED</Trans>
            </p>
          );
          break;
      default: break;
    }
    setAlert(
      <SweetAlert
        warning
        style={{
          display: "block",
          marginTop: "-100px",
        }}
        title={title}
        onConfirm={onConfirm}
        onCancel={onCancel}
        confirmBtnBsStyle="warning"
        cancelBtnBsStyle="danger"
        confirmBtnText={confirmBtnText}
        cancelBtnText={cancelBtnText}
        showCancel={true}
        showLoaderOnConfirm={true}
      >
        {paragraph}
      </SweetAlert>
    );
  };

  const getProblemCheckComponent = (flag) => {
    if (flag) {
      return <span style={{ color: 'red' }}><Trans>SCHEDULED_RIDE_PROBLEM</Trans></span>
    } else {
      return <span style={{ color: 'green' }}><Trans>SCHEDULED_RIDE_CLEAR</Trans></span>
    }
  }
  
  const getActions = (scheduledRide) => {
    let menuCancelButton = <MenuItem>
      <Button
        onClick={() => confirmAlert(scheduledRide, "cancel_ride")}
        bsSize="xsmall"
        bsStyle="danger"
      >
        <Trans>CANCEL</Trans>
      </Button>
      <br />
      <Button
        onClick={() => confirmAlert(scheduledRide, "demerge_ride")}
        bsSize="xsmall"
        bsStyle="warning"
      >
        <Trans>DEMERGE</Trans>
      </Button>
    </MenuItem>
  
  
    let menuEditButton = null
    if (!scheduledRide.started) {
      menuEditButton = <MenuItem>
        <Button
          onClick={() => editRideAlert(scheduledRide)}
          bsSize="xsmall"
          bsStyle="warning"
        >
          <Trans>EDIT_RIDE</Trans>
        </Button>
      </MenuItem>
    }
  
    return (
      <DropdownButton
        title={i18n.t("actions")}
        style={{ padding: "4%", margin: 0 }}
      >
        {menuCancelButton}
        {menuEditButton}
      </DropdownButton>
    );
  }

  function getRows() {
    const rows = props.data.original.subRows;

    return (
      <>
        {rows.map(row => {
          return (
            <tr className="driver-routes-subrow" onClick={ () => showRideInfoAlert(row)}>
              <td>{utcStringToLocalString(row.scheduled_datetime)}</td>
              <td>{utcStringToLocalString(row.onsite_datetime)}</td>
              <td>{SCHEDULED_RIDE_STATUS[row.status_id]}</td>
              <td style={{ textAlign: "center" }}><img src={RIDE_SERVICE_TYPE_ICONS[row.ride_service_type_id]} /></td>
              <td><b>{row.rider_name}</b></td>
              <td>{row.from_address}</td>
              <td>{row.to_address}</td>
              <td>{row.pick_up_company_name}</td>
              <td>{row.order_number}</td>
              <td>{row.customer_phone}</td>
              <td>{getProblemCheckComponent(row.problem_flag)}</td>
              <td onClick={(e) => e.stopPropagation()}>{getActions(row)}</td>
            </tr>
          );
        })}
      </>
    );
  }

  return (
    <div>
      {alert}
      {edit && <EditRidePopUp cancel={toggleEditPopUp} ride={selectedRide} />}
      <Table striped size="sm" className="sub-table">
        <thead>
          <tr>
            <th><Trans>SCHEDULED_DATE</Trans></th>
            <th><Trans>ONSITE_DATE</Trans></th>
            <th><Trans>status</Trans></th>
            <th><Trans>RIDE_SERVICE_NAME</Trans></th>
            <th><Trans>RIDER</Trans></th>
            <th><Trans>FROM_ADDRESS</Trans></th>
            <th><Trans>TO_ADDRESS</Trans></th>
            <th><Trans>BUSINESS_NAME</Trans></th>
            <th><Trans>ORDER_NUMBER</Trans></th>
            <th><Trans>CUSTOMER_PHONE</Trans></th>
            <th><Trans>HEALTHCHECK</Trans></th>
          </tr>
        </thead>
        <tbody className="table-api-subtable-container">
          {getRows()}
        </tbody>
      </Table>
    </div>
  )
}

export default DriverRouteSubTable